import React, { useState, useEffect } from 'react';

function RandomlyMovingCircles() {
  // const [circlePosition1, setCirclePosition1] = useState({ top: 0, left: 0 });
  const [circlePosition2, setCirclePosition2] = useState({ top: 0, left: 0 });

  // Calculate circleSize based on screen size
  const screenWidth = window.innerWidth;
  const circleSize = screenWidth > 850 ? 250 : 150; // Adjust as needed

  useEffect(() => {
    const generateRandomPosition = () => {
      const maxX = window.innerWidth - circleSize;
      const maxY = window.innerHeight - circleSize;
      const randomTop = Math.random() * maxY;
      const randomLeft = Math.random() * maxX;
      return { top: randomTop, left: randomLeft };
    };

    const moveCircles = () => {
      // setCirclePosition1(generateRandomPosition());
      setCirclePosition2(generateRandomPosition());
    };

    // Set initial positions for both circles
    // setCirclePosition1(generateRandomPosition());
    setCirclePosition2(generateRandomPosition());

    const interval = setInterval(moveCircles, 3000); // Move the circles every 5 seconds

    return () => clearInterval(interval);
  }, [circleSize]);

  return (
    <div>
      {/* <div
        className="circle"
        style={{
          position: 'absolute',
          width: `${circleSize}px`,
          height: `${circleSize}px`,
          background: 'radial-gradient(ellipse at top left, #bafbb0 0%, transparent 100%)',
          zIndex: -2,
          top: `${circlePosition1.top}px`,
          left: `${circlePosition1.left}px`,
          borderRadius: '50%', // Make it a circle
          transition: 'top 2s, left 2s', // CSS transitions for animated movement
        }}
      /> */}
      <div
        className="circle"
        style={{
          position: 'absolute',
          width: `${circleSize}px`,
          height: `${circleSize}px`,
          background: 'radial-gradient(ellipse at top right, #e7e765 0%, transparent 100%)',
          zIndex: -2,
          top: `${circlePosition2.top}px`,
          left: `${circlePosition2.left}px`,
          borderRadius: '50%', // Make it a circle
          transition: 'top 2s, left 2s', // CSS transitions for animated movement
        }}
      />
    </div>
  );
}

export default RandomlyMovingCircles;