import React, { useState, useEffect } from 'react';
import './MobileMenu.css';

function Sidebar({ isOpen, toggleSidebar, scrollToHomeSection, scrollToPortfolioSection, scrollToCVSection }) {
  // State to track the window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Function to handle window resize
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Add a resize event listener when the component mounts
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Close the sidebar when the window width exceeds 768px
  useEffect(() => {
    if (windowWidth >= 768 && isOpen) {
      toggleSidebar();
    }
  }, [windowWidth, isOpen, toggleSidebar]);

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="sidebar-content">
        <ul>
          <li onClick={() => {
            toggleSidebar();
            scrollToHomeSection();
          }}>Home</li>
          <li onClick={() => {
            toggleSidebar();
            scrollToPortfolioSection();
          }}>Portfolio</li>
          <li onClick={() => {
            toggleSidebar();
            scrollToCVSection();
          }}>CV</li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
