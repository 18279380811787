import './Footer.css';
import vector1 from "../Header/Vector.svg"
import linkedin from "../../components/Header/linkedin.svg"

function Footer() {
  return (
    <div id="cv-section" className="footer-container">
      <div className="contact-info">
        <div className="contact-details">
          <div className="contact-title">Contacts</div>
          <div className="contact-info-details">
            <div className="contact-info-text">nichita.ce@gmail.com</div>
            <div className="contact-info-text">+37379515225</div>
            <a href="https://www.linkedin.com/in/nichita-cebotari/" target="_blank" rel="noopener noreferrer">
            <img 
              alt="LinkedIn"
              src={linkedin}
              data-cursor-text="LinkedIn"
              data-cursor="-color-gray">
            </img>
            </a>
          </div>
        </div>
        <div className="made-by-container">
          <div className="made-by-text">Made with&nbsp;&nbsp;💚&nbsp;&nbsp;by</div>
          <div className="made-by-details">
            <img className="made-by-image" alt="Vector" src={vector1} />
            <div className="made-by-name">Nichita Cebotari</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
