import React from "react";
import "./CVDownload.css";
import pdfImage from "./CV.jpg";

function CVDownload() {
  const pdfUrl = "/CV_Nichita_Cebotari.pdf";

  const handleDownload = (download) => {
    if (download) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "CV_Nichita_Cebotari.pdf";
      link.click();
    } else {
      window.open(pdfUrl, "_blank");
    }
  };

  return (
    <div id="cv-section" className="download-button-container">
      <div className="cv-title-text">Curriculum Vitae</div>
      <div className="download-button-frame">
        <button className="glow-on-hover" onClick={() => handleDownload(true)}>
          Download PDF
        </button>
        <button className="glow-on-hover" onClick={() => handleDownload(false)}>
          Open CV
        </button>
      </div>
      <div className="download-button-image">
        <img src={pdfImage} alt="PDF Icon" className="pdf-image" />
      </div>
    </div>
  );
}

export default CVDownload;
