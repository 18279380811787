import Lottie from "lottie-react";
import allaroundAnimation from "./allaround.json";
import './ArrowAnim.css'

function ArrowAnim() {
    return (
      <div className="https-lottiefiles">
        <Lottie
          animationData={allaroundAnimation}
          loop
          autoPlay
        />
      </div>
    )
}

export default ArrowAnim;