import Lottie from "lottie-react";
import circledanim from "./circled.json";
import './Krutilki.css'

function ArrowAnim() {
    return (
      <div className="circled-lottie">
        <Lottie
          animationData={circledanim}
          loop
          autoPlay
        />
      </div>
    )
}

export default ArrowAnim;