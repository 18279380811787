import "./App.css";
import Header from "./components/Header/Header";
import Main from "./components/Main/Main";
import CanDo from "./components/CanDo/CanDo";
// import ImageDesktop from "./components/WorkTab/matcher_desktop.jpg";
// import ImageMobile from "./components/WorkTab/mobile.jpg";
import WorkTabContainer from "./components/WorkTab/WorkTabContainer";
import Footer from "./components/Footer/Footer";
import CVDownload from "./components/CVDownload/CVDownload"
import TickerWhite from "./components/TickerWhite/TickerWhite"
// import FigmaEmbed from './components/FigmaEmbed/FigmaEmbed';
import Snowfall from 'react-snowfall'

function App() {
  const text =
  "㋡ Scroll ㋡ Scroll ㋡ Scroll ㋡ Scroll ㋡ Scroll ㋡ Scroll ㋡ Scroll ㋡ Scroll ㋡ Scroll ㋡ Scroll ㋡ Scroll ㋡ Scroll ㋡ Scroll ㋡ Scroll ㋡ Scroll ㋡ Scroll ㋡ Scroll ㋡ Scroll ㋡ Scroll ㋡ Scroll ";
  return (
    
    <div className="App">

      <div className="desktop">
      <Snowfall color="#000" snowflakeCount={100} />
        <Header></Header>
        <Main></Main>
        <CanDo></CanDo>
        <TickerWhite text={text}></TickerWhite>
        <WorkTabContainer></WorkTabContainer>
        <CVDownload></CVDownload>
        {/* <FigmaEmbed fileId={figmaFileId} width="800px" height="600px" /> */}

      {/* <FigmaEmbed/> */}

        <Footer></Footer>
      </div>
    </div>
  );
}

export default App;
