import React, { useEffect, useState } from "react";
import "./Main.css";

function ResizableText() {
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setContainerWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  
  const paddingMargin = 20; // Add padding or margin of 20px

  const determineFontSize = () => {
    if (containerWidth < 600 - 2 * paddingMargin) {
      return "2.3rem"; // Small font size for narrow windows
    } else if (containerWidth < 850 - 2 * paddingMargin) {
      return "3rem"; // Medium font size for moderately sized windows
    } else if (containerWidth < 1000 - 2 * paddingMargin) {
      return "4rem"; // Medium font size for moderately sized windows
    } else {
      return "5rem"; // Large font size for wider windows
    }
  };

  const containerStyle = {
    paddingLeft: `${paddingMargin}px`, // Add left padding
    paddingRight: `${paddingMargin}px`, // Add right padding
    paddingTop: `${paddingMargin}px`, // Add top padding
  };

  const textClass = containerWidth < 600 ? "wrap-text" : "no-wrap-text";

  return (
    <div style={{ ...containerStyle }}>
      <p className={textClass} style={{ fontSize: determineFontSize() }}>
        <span>Hi, I'm</span>
        <span data-cursor="-lg" className="half-bold"> Nichita Cebotari</span>
        <span>
          ,<br />
          an
        </span>
        
          
          <span data-cursor="-lg" className="half-bold"> UI/UX</span> 
          <span> Designer </span>
      </p>
    </div>
  );
}

export default ResizableText;
