import React from 'react';
import './MenuDots.css'; // Make sure to create this CSS file

const MenuButton = ({ isOpen, toggleMenu }) => {
  return (
    <div className={`menu-button ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
      <div className="bar" />
      <div className="bar" />
      <div className="bar" />
    </div>
  );
};

export default MenuButton;
