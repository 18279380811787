
import "./CanDo.css"

function CanDo() {
  const titles = ["UI Design", 'UX Design', 'Wireframing', 'Responsive', 'Animations'];
  const titlesDev = ['HTML', 'CSS', "React"];
  return (
    <div className='what-i-can-do'>
      <div data-cursor-text="Take a look" className="what-i-can-do-text">
        What I can do:</div>

      <div className="green-tab-group">
      {titles.map((title, index) => (
        <div data-cursor-text="Design" className='green-tab' key={index}>{title}</div>
      ))}
      </div>
      <div className="transparent-tab-group">
      {titlesDev.map((title, index) => (
        <div data-cursor-text="Code" className='transparent-tab' key={index}>{title}</div>
      ))}
      </div>
      </div>
  );
}

export default CanDo;
