import React from "react";
import WorkTab from "./WorkTab";

function WorkTabContainer() {
  return (
    <div id="portfolio-section" data-cursor="-pointer" className="full-work-div">
      <h1>Portfolio</h1>
      <WorkTab
        title="Moving Company"
        // imageCount={2}
        imageNames={["moving1.jpg", "moving2.jpg", "moving3.jpg", "moving4.jpg", "moving5.jpg", "moving6.jpg", "moving7.jpg", "moving8.jpg", "moving9.jpg"]}
        description="Designed landing page for a moving company"
        link="https://www.figma.com/file/DBIkivMOAH2CjBJ3M6L1cr/Portfolio?type=design&node-id=0%3A1&mode=design&t=O8Sm4hH5ELowlxHh-1"
      />
      <WorkTab
        title="Barbershop"
        // imageCount={2}
        imageNames={["Home.jpg", "hairdress.jpg", "hairdress_matched.jpg", "haircut_registered.jpg", "tattoo.jpg", "tattoo_matched.jpg", "tattoo_registered.jpg", "About_us.jpg"]}
        description="Designed web application for matching the best barbershops and tattoo parlours"
        link="https://www.figma.com/file/DBIkivMOAH2CjBJ3M6L1cr/Portfolio?type=design&node-id=0%3A1&mode=design&t=O8Sm4hH5ELowlxHh-1"
      />
      <WorkTab
        title="Banners #1"
        // imageCount={2}
        imageNames={["AI_Var1.jpg", "AI_Var2.jpg", "AI_Var3.jpg", "AI_Var4.jpg", "AI_Var5.jpg"]}
        description="Designed banner variations for IaInActiune #1"
        link="https://www.figma.com/file/DBIkivMOAH2CjBJ3M6L1cr/Portfolio?type=design&node-id=0%3A1&mode=design&t=O8Sm4hH5ELowlxHh-1"
      />
      <WorkTab
        title="Banners #2"
        // imageCount={2}
        imageNames={["color3.jpg", "color4.jpg", "color1.jpg", "color2.jpg", "color5.jpg", "color6.jpg", "color7.jpg"]}
        description="Designed banner variations for IaInActiune #2"
        link="https://www.figma.com/file/DBIkivMOAH2CjBJ3M6L1cr/Portfolio?type=design&node-id=0%3A1&mode=design&t=O8Sm4hH5ELowlxHh-1"
      />
      <WorkTab
        title="Barbershop 2"
        // imageCount={6}
        imageNames={["barb1.jpg", "barb2.jpg", "barb3.jpg"]}
        description="(DEMO) Another variation of Barbershop"
        link="https://www.figma.com/file/DBIkivMOAH2CjBJ3M6L1cr/Portfolio?type=design&node-id=0%3A1&mode=design&t=O8Sm4hH5ELowlxHh-1"
      />
      <WorkTab
        title="Online Register"
        // imageCount={6}
        imageNames={["Login.jpg", "Login_dark.jpg", "Main.jpg", "Main_dark.jpg"]}
        description="Designed an online register for academic data management"
        link="https://www.figma.com/file/DBIkivMOAH2CjBJ3M6L1cr/Portfolio?type=design&node-id=0%3A1&mode=design&t=O8Sm4hH5ELowlxHh-1"
      />
      <WorkTab
        title="Lawyers"
        // imageCount={6}
        imageNames={["Home_tab.jpg", "nume1_CV.jpg", "Home_mobile.jpg", "admin_mobile.jpg"]}
        description="Designed an online register for academic data management"
        link="https://www.figma.com/file/DBIkivMOAH2CjBJ3M6L1cr/Portfolio?type=design&node-id=0%3A1&mode=design&t=O8Sm4hH5ELowlxHh-1"
      />
      <WorkTab
        title="Flowers Store"
        // imageCount={6}
        imageNames={["Flowers1.jpg", "Flowers2.jpg"]}
        description="(DEMO) Designed an online store for selling flowers"
        link="https://www.figma.com/file/DBIkivMOAH2CjBJ3M6L1cr/Portfolio?type=design&node-id=0%3A1&mode=design&t=O8Sm4hH5ELowlxHh-1"
      />
      <WorkTab
        title="Strawberry Candies"
        // imageCount={6}
        imageNames={["strawb.jpg"]}
        description="(DEMO) Designed an online store for selling candies"
        link="https://www.figma.com/file/DBIkivMOAH2CjBJ3M6L1cr/Portfolio?type=design&node-id=0%3A1&mode=design&t=O8Sm4hH5ELowlxHh-1"
      />
      <WorkTab
        title="Digital Art"
        // imageCount={6}
        imageNames={["art.jpg"]}
        description="(DEMO) Designed an art platform homepage"
        link="https://www.figma.com/file/DBIkivMOAH2CjBJ3M6L1cr/Portfolio?type=design&node-id=0%3A1&mode=design&t=O8Sm4hH5ELowlxHh-1"
      />
      <WorkTab
        title="Medical Database"
        // imageCount={6}
        imageNames={["pan1.jpg", "pan2.jpg", "pan3.jpg"]}
        description="(DEMO) Designed an art platform homepage"
        link="https://www.figma.com/file/DBIkivMOAH2CjBJ3M6L1cr/Portfolio?type=design&node-id=0%3A1&mode=design&t=O8Sm4hH5ELowlxHh-1"
      />
    </div>
  );
}

export default WorkTabContainer;
