import React, { useState, useEffect } from "react";
import "./Header.css";
import vector1 from "./Vector.svg";
import linkedin from "./linkedin.svg";
import RightBar from "../RightBar/RightBar";
import MenuButton from "../MenuDots/MenuDots";
import Sidebar from "../MobileMenu/MobileMenu";

function Header() {
  const text =
    "UI/UX DESIGN - WIREFRAMING - PROTOTYPING - RESPONSIVE - CSS - HTML - REACT";

  const [scrolled, setScrolled] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  function toggleSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
  }

  function scrollToPortfolioSection() {
    const headerHeight = 100;
    const portfolioSection = document.getElementById("portfolio-section");
    if (portfolioSection) {
      window.scrollTo({
        top: portfolioSection.offsetTop - headerHeight,
        behavior: "smooth",
      });
    }
  }

  function scrollToHomeSection() {
    const homeSection = document.getElementById("home-section");
    if (homeSection) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }
  
  function scrollToCVSection() {
    const cvSection = document.getElementById("cv-section");
    if (cvSection) {
      window.scrollTo({
        top: cvSection.offsetTop - 150,
        behavior: "smooth",
      });
    }
  }

  const tabData = ["Home", "Portfolio", "CV"];

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`header ${scrolled ? "scrolled" : ""}`}>
      <div className="frame">
      <div className={`logo ${scrolled ? "scrolled" : ""}`}>
          <div className="div">
          <a href="https://www.linkedin.com/in/nichita-cebotari/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
            <img
              className="vector"
              alt="Vector"
              src={vector1}
              data-cursor-text="Logo"
              data-cursor="-color-gray"
            />
          </a>
            <div className="header-text">Nichita Cebotari</div>

          </div>
        </div>
        <div
          className="header-tab"
          data-cursor-stick="#stick-me1"
          data-cursor="-opaque -lg"
          onClick={"Home" ? scrollToHomeSection : undefined}
        >
          <div className="header-tabs" id="stick-me1">
            Home
          </div>
        </div>

        <div
          className="header-tab"
          data-cursor-stick="#stick-me2"
          data-cursor="-opaque -lg"
          onClick={"Portfolio" ? scrollToPortfolioSection : undefined}
        >
          <div className="header-tabs" id="stick-me2">
            Portfolio
          </div>
        </div>

        <div
          className="header-tab"
          data-cursor-stick="#stick-me3"
          data-cursor="-opaque -lg"
          onClick={"CV" ? scrollToCVSection : undefined}
        >
          <div className="header-tabs" id="stick-me3">
            CV
          </div>
        </div>

        <div className={`phone ${scrolled ? "scrolled" : ""}`}>
          <div className="div">
            <div className="header-text">+37379515225</div>
            <a href="https://www.linkedin.com/in/nichita-cebotari/" target="_blank" rel="noopener noreferrer">
          <img
              // className="vector"
              alt="LinkedIn"
              src={linkedin}
              data-cursor-text="LinkedIn"
              data-cursor="-color-gray"
            />
            </a>
            </div>
        </div>
        <div className="burger-icon" onClick={toggleSidebar}>
          <MenuButton isOpen={isSidebarOpen} toggleMenu={toggleSidebar} />
        </div>
      </div>
      <RightBar text={text} />
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        tabData={tabData}
        scrollToHomeSection={scrollToHomeSection}
        scrollToPortfolioSection={scrollToPortfolioSection}
        scrollToCVSection={scrollToCVSection}
      />
    </header>
  );
}

export default Header;
