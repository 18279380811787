import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Krutilki1 from "../../assets/svgs/Krutilki";
import "./WorkTab.css";

function WorkTab({ title, imageNames, description, link }) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleNext = () => {
    setSelectedIndex((selectedIndex + 1) % imageNames.length);
  };

  const handlePrev = () => {
    setSelectedIndex(
      (selectedIndex - 1 + imageNames.length) % imageNames.length
    );
  };

  return (
    <div className="worktab-container">
      <div className="worktab-frame">
        <div className="worktab-content">
          <div className="worktab-left-container">
            <div className="worktab-title">{title}</div>
            <div className="worktab-carousel-container">
              <Carousel
                autoPlay
                infiniteLoop
                showThumbs={false}
                showStatus={false}
                showArrows={false}
                selectedItem={selectedIndex}
                renderIndicator={(onClickHandler, isSelected, index) => {
                  const dotStyle = {
                    width: "8px",
                    height: "8px",
                    backgroundColor: isSelected
                      ? "black"
                      : "rgba(128, 128, 128, 0.7)",
                    borderRadius: "50%",
                    display: "inline-block",
                    margin: "0 4px",
                    cursor: "pointer",
                    boxShadow: isSelected ? "0 0 7px white" : "none",
                  };

                  return (
                    <span
                      style={dotStyle}
                      onClick={() => onClickHandler(index)}
                      role="button"
                      tabIndex={0}
                      key={index}
                      aria-label={`Slide ${index}`}
                    />
                  );
                }}
              >
                {imageNames.map((imageName, index) => (
                  <div key={index}>
                    <img
                      className="worktab-image"
                      alt={imageName}
                      src={require(`./${imageName}`)}
                      style={{
                        width: "auto",
                        height: "25vw",
                        maxHeight: "300px",
                        maxWidth: "600px",
                        border: "1px solid #000000",
                        borderRadius: "2vw",
                      }}
                    />
                  </div>
                ))}
              </Carousel>
              <button
                onClick={handleNext}
                className="carousel-arrow custom-arrow next"
              >
                <svg
                  className="svg-worktab"
                  width="50%"
                  height="50%"
                  viewBox="0 0 91 126"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M90.0582 61.0296C89.5 56.5636 87.267 52.0977 83.9175 49.3065L26.4185 3.53049C19.1613 -2.05194 9.11293 -0.935454 3.53049 6.32171C-2.05194 13.5789 -0.935454 23.6273 6.32171 29.2097L48.19 62.7043L6.32171 96.1989C-0.935454 101.781 -2.05194 111.83 3.53049 119.087C9.11293 126.344 19.1613 127.461 26.4185 121.878L83.9175 74.9856C87.8252 71.6362 90.0582 67.1702 90.0582 62.1461V61.0296Z" />
                </svg>
              </button>
              <button
                onClick={handlePrev}
                className="carousel-arrow custom-arrow prev"
              >
                <svg
                  className="svg-worktab"
                  width="50%"
                  height="50%"
                  viewBox="0 0 91 126"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M90.0582 61.0296C89.5 56.5636 87.267 52.0977 83.9175 49.3065L26.4185 3.53049C19.1613 -2.05194 9.11293 -0.935454 3.53049 6.32171C-2.05194 13.5789 -0.935454 23.6273 6.32171 29.2097L48.19 62.7043L6.32171 96.1989C-0.935454 101.781 -2.05194 111.83 3.53049 119.087C9.11293 126.344 19.1613 127.461 26.4185 121.878L83.9175 74.9856C87.8252 71.6362 90.0582 67.1702 90.0582 62.1461V61.0296Z"
                    transform="rotate(180 45.5291 63.0148)"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="worktab-right-container">
            <p className="worktab-description">{description}</p>
            <a href={link} className="glow-on-hover" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
              <div className="worktab-button-title">Open now</div>
              <Krutilki1 />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkTab;
