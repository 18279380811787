// import Krutilki from "../../assets/svgs/Krutilki";
import ArrowAnim from "../ArrowAnim/ArrowAnim";
import Circle from "../Circle/Circle";
import TickerBlack from "../TickerBlack/TickerBlack";
import TextSize from "./TextSize";
import "./Main.css";
import BackAnim from "../BackAnim/BackAnim";

function Main() {
  function scrollToPortfolioSection() {
    const headerHeight = 100;
    const portfolioSection = document.getElementById("portfolio-section");
    if (portfolioSection) {
      window.scrollTo({
        top: portfolioSection.offsetTop - headerHeight,
        behavior: "smooth",
      });
    }
  }
  function scrollToCVSection() {
    const cvSection = document.getElementById("cv-section");
    if (cvSection) {
      window.scrollTo({
        top: cvSection.offsetTop - 150,
        behavior: "smooth",
      });
    }
  }
  const text =
    "Scroll to look at my portfolio - Site is made by me - Hire Me Now - Scroll to look at my portfolio - Site is made by me - Hire Me Now -";
  return (
    <div id="home-section" data-cursor-text="Hi!" className="full-screen-main">
      <div className="full-screen-content">
        <TextSize />
        <div className="buttons-box">
          <div className="buttons-box-content">
            {/* <div className="button-main"> */}
            {/* <div className="button-main-text">Hire Me</div> */}

            <button data-cursor="-md" className="glow-on-hover" onClick={scrollToPortfolioSection}>
              Portfolio &nbsp;&nbsp;&nbsp;→
            </button>

            <button data-cursor="-md" className="glow-on-hover" onClick={scrollToCVSection}>
  Check my CV &nbsp;&nbsp;&nbsp;→
</button>

            {/* </div> */}
            {/* <div className="button-main"> */}
            {/* <div className="button-main-text">Check my CV</div> */}
            {/* <Krutilki /> */}
            {/* </div> */}
          </div>
        </div>
      </div>
      <ArrowAnim />
      <BackAnim />
      <Circle />

      <TickerBlack text={text} />
    </div>
  );
}

export default Main;
