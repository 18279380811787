import "./TickerBlack.css"

const TickerBlack = ({ text }) => {
  return (
    <div data-cursor="-opaque" className="ticker-container-black">
      <div className="ticker-text-black">
        {text} {' '}
        {text} {' '}
      </div>
    </div>
  );
};

export default TickerBlack;