import Lottie from "lottie-react";
import backgroundAnim from "./BackAnim0.json";
import './BackAnim.css'

function BackAnim() {
    return (
      <div className="background-waves">
        <Lottie
          animationData={backgroundAnim}
          loop
          autoPlay
        />
      </div>
    )
}

export default BackAnim;