import "./RightBar.css";

const RightBar = ({ text }) => {
  return (
    <div className="ticker-container-vertical">
      <div className="ticker-text-vertical">
        {text} {' '} 
        {text} {' '}
      </div>
      <div className="rectangle-vertical"/>
    </div>
  );
};

export default RightBar;
